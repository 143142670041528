/* TournamentBracket.css */
.tournament-bracket {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  background-color: #f8f9fa;
  color: #212529;
  padding: 20px 20px;
  min-height: 100vh;
}
.input-underline {
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 5px;
  font-size: 1rem;
  outline: none;
  background: transparent;
  color: #333;
  transition: border-color 0.2s ease-in-out;
}

.input-underline:focus {
  border-bottom-color: #7ad446;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.bracket {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.round {
  font-size: 1rem;
  color: #495057;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  width: 200px;
}
.round1 {
  font-size: 1rem;
  color: #495057;
  margin-bottom: 10px;
  flex-direction: row;
  width: 250px;
  display: flex;
  align-items:center;
  justify-content:center;
}

.match {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.match:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

button {
  background-color: #1e7e34;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  margin: 5px 0;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

button:hover {
  background-color: #155d27;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

span {
  font-weight: bold;
  color: #6c757d;
  margin: 10px 0;
}

.winner {
  margin-top: 40px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #155d27;
}
