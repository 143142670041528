body > #root > div {
  height: 100vh;
  width: 100%;
}
body {
  font-family: 'Montserrat', sans-serif;
}
h1 {
  font-family: 'Montserrat', sans-serif;
}
h2 {
  font-family: 'Montserrat', sans-serif;
}
h3 {
  font-family: 'Montserrat', sans-serif;
}
h4 {
  font-family: 'Montserrat', sans-serif;
}
.list-group-item {
  font-family: 'Montserrat', sans-serif;
  font-size:11pt;
}
.container {
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
}
.avatar {
  width: 50px;
  height:50px;
  font-weight:200;
  font-size: 24pt;
  color:#999;
  background-color:#eee;
  border-radius:25px;
  align-items:center;
  justify-content:center;
  text-align:center;
}
.navbar {
    position: relative;
    height:50px;
}
.brand {
    position: absolute;
    left: 50%;
    margin-left: -75px !important;  /* 50% of your logo width */
    display: block;
    top:8px;
}
.link {
  font-family: 'Montserrat', sans-serif;
  font-size: 12pt;
  font-weight: bold;
  text-decoration: none;
  color:#333;
}
.prow {
  align-items: center;
  justify-content:center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.displayName {
  font-family: 'Montserrat';
  font-size: 14pt;
  color: '#333';
  font-weight:bold;
}
.hometown {
  font-family: 'Montserrat';
  font-size: 10pt;
  color: '#333';
}
.label {
  font-family: 'Montserrat';
  font-size: 14pt;
  color: '#333';
  font-weight:bold;
}
