.gameScore {
  font-weight:bold;
  color:'#ccc';
  font-size: 30px;
  align-items:center;
  justify-content:center;
  display: flex;
  background-color:#ddd;
  border-radius:5px;
  padding:10px;
  min-width:70px;
}
.description {
  font-weight:300;
  color:'#333';
  font-size: 14px;
  align-items:center;
  justify-content:center;
  display: flex;
}
