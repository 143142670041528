
.title {
  font-weight:bold;
  font-size: 30pt;
  color:#000;
  text-align:center;

}
.lbRow {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  padding:10px;
  margin-bottom: 5px;
  border-radius: 5px;
  padding: 15px;
}
.lbDisplayName {
  font-weight:bold;
  font-size: 14pt;
  color:#000;
  justify-content: start;
  align-items: start;
  display: flex;
}
.lbHometown {
  font-weight:200;
  font-size: 10pt;
  color:#333;
  justify-content: start;
  align-items: start;
  display: flex;
}
.lbPlace {
  font-weight:bold;
  font-size: 8pt;
  color:#fff;
  width:20px;
  height:20px;
  position:absolute;
  top:0;
  left:0;
  border-radius: 10px;
  background-color: #000;
  justify-content: center;
  align-items: center;
  display: flex;
}
.lbScore {
  font-weight:bold;
  font-size: 16px;
  color:#333;
  width:50px;
  height:50px;
  border-radius: 5px;
  background-color: #eee;
  justify-content: center;
  align-items: center;
  display: flex;

}
.lbCard {
  background-color: #fff;
  border-radius: 10px;
  align-items: center;
  justify-content: start;
  display: flex;
  flex-direction: column;
  width: 350px;
  min-height: 200px;
  margin-top:20px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 30px;
}

.lbTitle {
  font-weight:bold;
  font-size: 20pt;
  color:#333;
}
