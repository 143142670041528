
.puttData {
  font-size:10pt;
  color:#333;
  font-weight:bold;
}
.puttText {
  font-size:10pt;
  color:#333;
  margin-left: 5px;
}
