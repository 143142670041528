.label {
  font-size: 10pt;
  color:#000;
}
.gameStat {
  font-weight:bold;
  font-size: 20pt;
  color:#000;
}
.gameCard {
  background-color: #fff;
  border-radius: 10px;
  align-items: center;
  justify-content: start;
  display: flex;
  padding:20px;
  flex-direction: column;
  min-height: 200px;
  margin-top:20px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 30px;
}
.distance {
  font-size:14pt;
  color:#333;
  font-weight:bold;
  text-align:center;
}
.timeAgo {
  font-size: 10pt;
  color:#666;

}
.puttCard {
  width:320px;
  background-color:#fff;
  border-radius: 10px;
  box-shadow:4px 4px 2px #eee;
  margin-left:5px;
  margin-right:5px;
  align-items:center;
  flex-direction: row;
  display: flex;
}
.gameTitle {
  font-weight:bold;
  font-size: 20pt;
  color:#333;
}
.gameScore {
  font-weight:bold;
  font-size: 16pt;
  color:#000;
}
.gameDescription {
  font-size: 12pt;
  color:#666;
}
