
.teeRow {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 5px;
}

.markDiv {
  align-items:center;
  justify-content:center;
  text-align:center;
}

.tee {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  margin-right:10px;
  margin-left:10px;
  border-width: 1px;
  border-color:#999;
  background-color:#053220;
  align-items:center;
  justify-content:center;
  text-align:center;
}

.teeOn {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color:#fff;
  margin-right:10px;
  margin-left:10px;
  border-width: 1px;
  border-color:#999;
  border-style:solid;
  align-items:center;
  justify-content:center;
  text-align:center;
}
